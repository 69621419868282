import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SplashScreen from './views/SplashScreen';
import LoginScreen from './views/LoginScreen';
import RegisterScreen from './views/RegisterScreen';
import ProductCatalog from './views/ProductCatalog';
import ProductCatalogOverlay from './views/ProductCatalogOverlay';
import { useAppId } from './AppIdContext';
import configLoader from './utils/configLoader';
import initializeI18n from './i18n';
import ItemDetails from "./views/ItemDetails";
import Cart from "./views/Cart";
import Orders from "./views/Orders";
import OrderDetails from "./views/OrderDetails";
import Address from "./views/Address";
import AddressForm from "./views/AddressForm";
import Checkout from "./views/Checkout";
import CheckoutConfirmation from "./views/CheckoutConfirmation";
import KashierPayment from "./views/KashierPayment";

import Skeleton from "react-loading-skeleton";

const App = () => {
    const [config, setConfig] = useState(null);
    const [categories, setCategories] = useState([]);
    const [offers, setOffers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const appId = useAppId();
    const navigate = useNavigate();

    import(`./projects/${appId}/app_style.css`);

    const logoPath = `/projects/${appId}/images/product_catalog_block_logo.png`;

    const backgroundPath = `/projects/${appId}/images/cover.png`;

    const style = {
        background: `url(${backgroundPath}) no-repeat center center `,
        backgroundSize: 'cover',
    };

    useEffect(() => {
        const loadConfigAndInitializeI18n = async () => {
            try {
                await initializeI18n(appId);
                const loadedConfig = await configLoader(appId);
                setConfig(loadedConfig);
            } catch (error) {
                console.error('Failed to load config or initialize i18n:', error);
            }
        };

        loadConfigAndInitializeI18n();
    }, [appId]);


    const renderLoadingSkeletons = () => (
        <div className="splash_layout">
            <Skeleton height={800} />
        </div>
    );

    if (!config) {
        return renderLoadingSkeletons(); // Show a loading state while configuration is being loaded
    }

    const handleSplashComplete = (nextScreen, loadedCategories, loadedOffers, loadedBranches) => {
        setCategories(loadedCategories);
        setOffers(loadedOffers);
        setBranches(loadedBranches);
        navigate(nextScreen || '/catalog');
    };

    const handleItemSelected = (item) => {
        setSelectedItem(item);
    };

    return (
        <div class="app_holder">
            <div className="App">
                <Routes>
                    <Route path="/splash" element={<SplashScreen onComplete={handleSplashComplete} config={config} />} />
                    <Route path="/login" element={<LoginScreen config={config} />} />
                    <Route path="/register" element={<RegisterScreen config={config} />} />
                    <Route path="/catalog" element={<ProductCatalog config={config} onItemSelect={handleItemSelected} />} />
                    <Route path="/overlay" element={<ProductCatalogOverlay config={config} />} />
                    <Route path="/itemDetails" element={<ItemDetails item={selectedItem} config={config} />} />
                    <Route path="/cart" element={<Cart config={config} />} />
                    <Route path="/checkout" element={<Checkout config={config} />} />
                    <Route path="/checkout/confirmation" element={<CheckoutConfirmation config={config} />} />
                    <Route path="/address" element={<Address />} />
                    <Route path="/address/form" element={<AddressForm config={config} />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/order/details" element={<OrderDetails />} />
                    <Route path="/kashierPayment" element={<KashierPayment />} />
                    <Route path="/" element={<SplashScreen onBack={() => navigate('/catalog')} onComplete={handleSplashComplete} config={config} />} />
                </Routes>
            </div>
            <div className="cover" style={style}>
                <div class="cover-logo"><img src={logoPath} /></div>
            </div>
        </div>

    );
};

export default App;
