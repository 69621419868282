import React, { useState } from 'react';

const ItemDetailsBlockVariantsGroups = ({ variationGroups = [], onVariationSelect }) => {
    const [selectedVariants, setSelectedVariants] = useState({});

    const handleVariationChange = (groupIndex, group, variant) => {
        setSelectedVariants((prevState) => {
            const groupKey = `group-${groupIndex}`;
            let newSelected = { ...prevState };

            if (group.field_variant_multiple?.[0] === "1") {
                if (newSelected[groupKey]?.includes(variant.name)) {
                    newSelected[groupKey] = newSelected[groupKey].filter(v => v !== variant.name);
                } else {
                    newSelected[groupKey] = [...(newSelected[groupKey] || []), variant.name];
                }
            } else {
                newSelected[groupKey] = variant.name;
            }

            onVariationSelect(groupIndex, variant);
            return newSelected;
        });
    };

    const isSelected = (groupIndex, group, variantName) => {
        const groupKey = `group-${groupIndex}`;
        return group.field_variant_multiple?.[0] === "1"
            ? selectedVariants[groupKey]?.includes(variantName)
            : selectedVariants[groupKey] === variantName;
    };

    return (
        <div className="item_details_block_variants_groups">
            {variationGroups.map((group, groupIndex) => (
                <div className="item_details_block_variants">
                    <div className="item_details_block_variants_title bold">{group.field_variant_group_name?.[0]}</div>
                    <div className="item_details_block_variants" key={groupIndex}>
                        <div className="item_details_block_variants_groups_list">
                            {group.field_variants?.map((variant, variantIndex) => (
                                <div
                                    className={`item_details_block_variants_item ${isSelected(groupIndex, group, variant.name) ? 'item_details_block_variants_item_selected' : ''}`}
                                    key={variantIndex}
                                    onClick={() => handleVariationChange(groupIndex, group, variant)}
                                >
                                    <input
                                        className="item_details_block_variants_item_indicator"
                                        type={group.field_variant_multiple?.[0] === "1" ? "checkbox" : "radio"}
                                        name={`group-${groupIndex}`}
                                        value={variant.name}
                                        checked={isSelected(groupIndex, group, variant.name)}
                                        onChange={() => handleVariationChange(groupIndex, group, variant)}
                                    />
                                    <div className={`item_details_block_variants_item_name bold ${isSelected(groupIndex, group, variant.name) ? 'item_details_block_variants_item_name_selected' : ''}`}>{variant.name}</div>
                                    {Number(variant.price) > 0 && (
                                        <div className={`item_details_block_variants_item_price  ${isSelected(groupIndex, group, variant.name) ? 'item_details_block_variants_item_price_selected' : ''}`}>{variant.price}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ItemDetailsBlockVariantsGroups;
