import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {fetchPaymentMethods, fetchWalletCredit} from "../services/api";
import {useAppId} from "../AppIdContext";



const CheckoutBlockPayment = ({setPaymentMethod, setUseCredit}) => {
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [useWalletBalance, setUseWalletBalance] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const appId = useAppId();

    // Fetch payment methods on component load
    useEffect(() => {
        const loadPaymentMethods = async () => {
            const methods = await fetchPaymentMethods(appId);
            setPaymentMethods(methods);
        };
        const loadWalletBalance = async () => {
            const wallet = await fetchWalletCredit(appId);
            if (wallet.length > 0) {
                setWalletBalance(wallet[0]?.balance_after || 0);
            }
        };
        loadPaymentMethods();
        loadWalletBalance();
    }, []);

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
        setSelectedPaymentMethod(method);
    };

    const handleWalletToggle = () => {
        setUseCredit(!useWalletBalance, walletBalance);
        setUseWalletBalance(!useWalletBalance);
    };

    return (
        <div className="checkout_block_payment">
            <div className="checkout_block_payment_title bold">
                {t("checkout_block_payment_title_text")}
            </div>

            {/* Wallet balance checkbox */}
            {walletBalance > 0 && (
                <div className="checkout_block_payment_wallet_balance">
                    <label>
                        <input
                            type="checkbox"
                            checked={useWalletBalance}
                            onChange={handleWalletToggle}
                        />
                        {t("checkout_block_payment_wallet_balance_text")} ({walletBalance})
                    </label>
                </div>
            )}


            {/* Payment method radio buttons */}
            <div className="payment_options">
                {paymentMethods.map((method) => (
                    <div key={method.id} className="checkout_block_payment_method">
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value={method.type}
                                checked={selectedPaymentMethod === method.type}
                                onChange={() => handlePaymentMethodChange(method.type)}
                            />
                            {method.type == "COD" ? t("checkout_block_payment_method_cod_text") : t("checkout_block_payment_method_credit_card_text")}
                        </label>
                    </div>
                ))}
            </div>

                {/*<div className="payment_options">
                    <div  className="checkout_block_payment_method">
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="COD"
                                checked
                            />
                            {t("checkout_block_payment_method_cod_text")}
                        </label>
                    </div>
                 </div>*/}
        </div>
    );
};

export default CheckoutBlockPayment;
