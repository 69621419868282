import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const KashierPayment = () => {
    const location = useLocation(); // Get payment link from navigation state
    const navigate = useNavigate();  // Used to redirect after payment
    const iframeRef = useRef(null);  // Reference to the iframe element
    const [paymentStatus, setPaymentStatus] = useState(''); // Track the payment status
    const orderNumber = location.state?.orderNumber;
    const paymentLink = location.state?.paymentLink; // Access payment link passed via state

    useEffect(() => {
        if (!paymentLink) {
            toast.error("Invalid payment link");
            navigate(-2);  // Go back instead of navigating to checkout
            return;
        }

        const handleMessage = (event) => {
            console.log(orderNumber);
            if (event.origin !== 'https://mobstep.com') {
                return; // Ensure the message is from the correct origin
            }
            if (event.data === 'payment-success') {
                setPaymentStatus('success');
                toast.success("Payment successful!");
                navigate('/checkout/confirmation', { state: { orderNumber: orderNumber } });
            } else if (event.data === 'payment-failure') {
                setPaymentStatus('failure');
                toast.error("Payment failed!");
                navigate(-2);  // Go back to the previous page instead of navigating to checkout
            }
        };

        window.addEventListener('message', handleMessage);

        return () => window.removeEventListener('message', handleMessage); // Cleanup on unmount
    }, [navigate, paymentLink]);

    return (
        <div className="kashier-payment-container">
            {paymentLink && (
                <iframe
                    ref={iframeRef}
                    src={paymentLink}
                    title="Kashier Payment"
                    style={{ width: '100%', height: '90vh', border: 'none' }}
                />
            )}

            {paymentStatus === 'failure' && (
                <div className="payment-failure">
                    <p>Payment failed. Please try again.</p>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default KashierPayment;
