import React, { useState, useEffect, useRef } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useNavigate } from 'react-router-dom';
import { fetchItems, fetchCategories, fetchOffers, fetchBranches } from '../services/api';
import { useAppId } from "../AppIdContext";

const ProductCatalog = ({ categories: initialCategories, offers: initialOffers, branches: initialBranches, config }) => {
    const appId = useAppId();
    const sidebarRef = useRef(null);
    const [branches, setBranches] = useState(initialBranches || []);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [loading, setLoading] = useState(!initialCategories || !initialOffers || !initialBranches);
    const [productCatalogPlacements, setPlacements] = useState(null);
    const [isSidebarOpen, setSidebarOpen] = useState(false); // Sidebar state
    const navigate = useNavigate();

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/product_catalog_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const [loadBranches] = await Promise.all([
                    fetchBranches(appId),
                ]);
                setBranches(loadBranches);
            } catch (error) {
                console.error('Failed to load branches:', error);
            } finally {
                setLoading(false);
            }
        };

        if ( !initialBranches) {
            loadInitialData();
        }
    }, [initialBranches]);



    const handleInitialCategory = (id) => {
        setSelectedCategoryId(id);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isSidebarOpen) {
                setSidebarOpen(false); // Close the sidebar if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const handleToggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };


    const handleCategoryNavigation = async (categoryId, categoryName) => {
        setSelectedCategoryId(categoryId);
        setSelectedCategoryName(categoryName);
        if (config.product_catalog_block_categories_category_overlay_enable) {
            console.log(categoryName);
            navigate('/overlay', { state: { selectedCategoryId: categoryId, selectedCategoryName: categoryName, config, userCredits: 0, mode: 'items' } });
        }
    };

    const data = {
        product_catalog_block_items: {
            config,
            selectedCategoryId,
        },
        product_catalog_block_listing_group: {
            config,
        },
        product_catalog_block_menu_icon: {
            onClick: handleToggleSidebar,
        },
        product_catalog_block_categories: {
            onSelectCategory: handleCategoryNavigation,
            initialCategory: handleInitialCategory,
            config,
        },
        product_catalog_block_tabs: {
            onCategorySelect: handleCategoryNavigation,
            initialCategory: handleInitialCategory,
            config,
        },
    };

    if (branches.length > 0) {
        data.product_catalog_block_contact = {
            phoneNumber: branches[0].phone,
        };
        data.product_catalog_block_whatsapp = {
            whatsappNumber: branches[0].whatsapp,
        };
    }


    if (!config || loading || !productCatalogPlacements) {
        return (<div></div>); // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(productCatalogPlacements, data);

    return (
        <div className="product_catalog_layout ">
            {config.product_catalog_layout_actionbar_enable && (
                <div className="product_catalog_layout_toolbar_position_toolbar product_catalog_layout_toolbar toolbar">
                    <div className="product_catalog_layout_toolbar_position_toolbar_start toolbar_start">{renderer.renderComponents('product_catalog_layout_toolbar_position_toolbar_start')}</div>
                    <div className="product_catalog_layout_toolbar_position_toolbar_center toolbar_center">{renderer.renderComponents('product_catalog_layout_toolbar_position_toolbar_center')}</div>
                    <div className="product_catalog_layout_toolbar_position_toolbar_end toolbar_end">{renderer.renderComponents('product_catalog_layout_toolbar_position_toolbar_end')}</div>
                </div>
            )}

            <div className=" product_catalog_layout_header_holder">
                <div className="product_catalog_layout_header">
                    <div className="product_catalog_layout_header_overlay"></div>
                    <div className="product_catalog_layout_header_position_featured">{renderer.renderComponents('product_catalog_layout_header_position_featured')}</div>
                    <div className="product_catalog_layout_header_position_featured_bottom">
                        <div className="product_catalog_layout_header_position_featured_bottom_start">
                            {renderer.renderComponents('product_catalog_layout_header_position_featured_bottom_start')}
                        </div>
                        <div className="product_catalog_layout_header_position_featured_bottom_end">
                            {renderer.renderComponents('product_catalog_layout_header_position_featured_bottom_end')}
                        </div>
                    </div>
                </div>
            </div>


            <div className="product_catalog_layout_content">
                <div className="product_catalog_layout_content_top_position_content_top">{renderer.renderComponents('product_catalog_layout_content_top_position_content_top')}</div>
                <div className="product_catalog_layout_content_position_content">{renderer.renderComponents('product_catalog_layout_content_position_content')}</div>
            </div>
            {config.product_catalog_layout_footer_enable && (
                <div className="product_catalog_layout_footer_holder">
                    <div className="product_catalog_layout_footer">
                        <div className="product_catalog_layout_footer_positions">
                            <div className="product_catalog_layout_footer_position_footer_start">{renderer.renderComponents('product_catalog_layout_footer_position_footer_start')}</div>
                            <div className="product_catalog_layout_footer_position_footer_center">{renderer.renderComponents('product_catalog_layout_footer_position_footer_center')}</div>
                            <div className="product_catalog_layout_footer_position_footer_end">{renderer.renderComponents('product_catalog_layout_footer_position_footer_end')}</div>
                        </div>
                    </div>
                </div>
            )}

            {isSidebarOpen && (
                <div className="product_catalog_layout_sidebar product_catalog_layout_navigation" ref={sidebarRef}>
                    <div className="product_catalog_layout_side_menu" >{renderer.renderComponents('product_catalog_layout_side_menu_position_side_menu')}</div>
                </div>
            )}
        </div>
    );
};

export default ProductCatalog;
