// src/components/ProductCatalogBlockUserMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { isLoggedIn, handleLogout } from '../services/auth';
import { useTranslation } from 'react-i18next';
import {useAppId} from "../AppIdContext";

const ProductCatalogBlockUserMenu = () => {
    const { t } = useTranslation();
    const loggedIn = isLoggedIn();
    const appId = useAppId();

    const historyIcon = `/projects/${appId}/images/product_catalog_block_user_menu_item_order_history_icon.png`;
    const deliveryInformationIcon = `/projects/${appId}/images/product_catalog_block_user_menu_item_delivery_information_icon.png`;
    const loginIcon = `/projects/${appId}/images/product_catalog_block_user_menu_item_login_icon.png`;
    const logoutIcon = `/projects/${appId}/images/product_catalog_block_user_menu_item_logout_icon.png`;

    const orderPath = loggedIn ? "/orders" : "/login";
    const addressPath = loggedIn ? "/address" : "/login";

    return (
        <div className="product_catalog_block_user_menu">
                <Link className="product_catalog_block_user_menu_item pointer" to={orderPath}>
                    <img className="product_catalog_block_user_menu_item_icon" src={historyIcon} alt={t('product_catalog_block_user_menu_item_order_history_text')} />
                    <span className="product_catalog_block_user_menu_item_text bold">{t('product_catalog_block_user_menu_item_order_history_text')}</span>
                </Link>
                <Link className="product_catalog_block_user_menu_item pointer" to={addressPath}>
                    <img className="product_catalog_block_user_menu_item_icon" src={deliveryInformationIcon} alt={t('product_catalog_block_user_menu_item_delivery_information_text')} />
                    <span className="product_catalog_block_user_menu_item_text bold">{t('product_catalog_block_user_menu_item_delivery_information_text')}</span>
                </Link>
                {!loggedIn && (
                    <Link className="product_catalog_block_user_menu_item pointer" to="/login">
                        <img className="product_catalog_block_user_menu_item_icon" src={loginIcon} alt={t('product_catalog_block_user_menu_item_login_text')} />
                        <span className="product_catalog_block_user_menu_item_text bold">{t('product_catalog_block_user_menu_item_login_text')}</span>
                    </Link>
                )}
                {loggedIn && (
                    <div className="product_catalog_block_user_menu_item pointer" onClick={handleLogout}>
                        <img className="product_catalog_block_user_menu_item_icon" src={logoutIcon} alt={t('product_catalog_block_user_menu_item_logout_text')} />
                        <span className="product_catalog_block_user_menu_item_text bold">{t('product_catalog_block_user_menu_item_logout_text')}</span>
                    </div>
                )}
        </div>
    );
};

export default ProductCatalogBlockUserMenu;
