// src/components/ProductCatalogBlockContact.js
import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppId} from "../AppIdContext";

const ProductCatalogBlockContact = ({phoneNumber}) => {
    const appId = useAppId();
    const contactIcon = `/projects/${appId}/images/product_catalog_block_contact_icon.png`;
    const {t} = useTranslation();


    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <div className="product_catalog_block_contact block_group draggable" onClick={handleCallClick}>
            <img className="product_catalog_block_contact_icon" src={contactIcon} alt="Call support" />
            <div className="flex_1">
                <div className="product_catalog_block_contact_title product_catalog_block_contact_title_text bold">{t("product_catalog_block_contact_title")}</div>
                <div className="product_catalog_block_contact_number product_catalog_block_contact_number_text bold">{phoneNumber}</div>
            </div>
        </div>
    );
};

export default ProductCatalogBlockContact;
