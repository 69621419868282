import React from 'react';
import {useTranslation} from "react-i18next";

const ItemDetailsBlockSpecs = ({ specs }) => {

    const {t} = useTranslation();

    return (
        <div className="item_details_block_specs ">
            <div className="item_details_block_specs_title item_details_block_specs_title_text bold">{t("item_details_block_specs_title_text")}</div>
            <div className="item_details_block_specs_holder">
                {specs.map((spec, index) => (
                    <div key={index} className="item_details_block_specs_item flex">
                        <div className="item_details_block_specs_item_label bold ">{spec.label}:</div>
                        <div className="item_details_block_specs_item_value flex_1">{spec.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ItemDetailsBlockSpecs;
