// services/api.js
import axios from 'axios';
import { getToken } from './auth';

export const fetchCategories = async (appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_category/?fields=id,name,category_image,category_icon,status&is_subcategory=false&sort=weight,asc&status=1&application_id=${appId}`);
    return response.data;
};

export const fetchSubCategories = async (appId, categoryId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_category/?fields=id,name,category_image,category_icon,status&sort=weight,asc&status=1&application_id=${appId}&parent=${categoryId}`);
    return response.data;
};

export const fetchOffers = async (appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_offer/?fields=id,name,image&application_id=${appId}`);
    return response.data;
};

export const fetchListings = async (appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_listing/?fields=id,name,icon,items,status&application_id=${appId}"&status=1&sort=weight,asc`);
    return response.data;
};

export const fetchBranches = async (appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_branch/?fields=name,status,busy,workflow,tax,phone,whatsapp&application_id=${appId}`);
    return response.data;
};

export const fetchItems = async (category_id, appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_item/?fields=id,name,id,item_image,description,short_description,base_price,sale_price,field_item_variation_group,field_specs,stock,sku,loyalty_points,rewards_catalog,field_item_availability_multiple,field_item_availability_base,field_item_availability_required&sort=weight,asc&available=1&application_id=${appId}&category_id=${category_id}`);
    return response.data;
};

export const fetchLoyaltyItems = async (appId) => {
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_item/?fields=id,name,id,item_image,description,short_description,base_price,sale_price,field_item_variation_group,field_specs,stock,sku,loyalty_points,rewards_catalog,field_item_availability_multiple,field_item_availability_base,field_item_availability_required&sort=weight,asc&available=1&application_id=${appId}&rewards_catalog=1`);
    return response.data;
};


export const fetchWalletCredit = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_customer_wallet/?fields=id,uuid,amount,balance_after,balance_before,type,order_id,staff_id&application_id=${appId}&range=0,1&sort=created,desc`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};

export const fetchPaymentMethods = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_payment_method?fields=id,type,status,config&status=1&application_id=${appId}`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};

export const fetchAddresses = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_address/?fields=id,uuid,full_name,phone,flat,building,street,postal_code,area,city,province,country,lat,lng,customer_id,default,phone,mail&sort=default,desc&application_id=${appId}`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};


export const fetchLoyaltyPoints = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_loyaltypoints/?fields=balance_after,customer_id&application_id=${appId}`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};



// Example function to simulate fetching wallet balance
export  const fetchWalletBalance = async () => {
    // Simulating wallet balance
    return { balance: 50.00 };
};


export const fetchOrders = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_order/?fields=id,uuid,service,coupon,tax,service_fee,subtotal,discount,total,status,payment,city,area,address,phone,lat,lng,customer_id,admin_id,application_id,langcode,created,changed,field_order_item,pending,wallet_amount&pending=false`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};


export const fetchOrderDetails = async (id) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_order/?fields=id,uuid,service,coupon,tax,service_fee,subtotal,discount,total,status,payment,city,area,address,phone,lat,lng,customer_id,admin_id,application_id,langcode,created,changed,field_order_item,pending,wallet_amount&id=${id}`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};


export const fetchDefaultAddresses = async (appId) => {
    const token = getToken();
    const response = await axios.get(`https://mobstep.com/api/v2.0/apps_address/?fields=id,uuid,full_name,phone,flat,building,street,postal_code,area,city,province,country,lat,lng,customer_id,default,phone,mail&sort=default,desc&default=1&application_id=${appId}`, {
        headers: {'mobstep-customer-token': token}
    });
    return response.data;
};


export const validateCoupon = async (code) => {
    const token = getToken();
    const response = await axios.post('https://mobstep.com/api/v2.0/coupon/validate', { code }, {
        headers: { 'mobstep-customer-token': token }
    });
    return response.data;
};

export const fetchAutoCoupons = async () => {
    const token = getToken();
    const response = await axios.get('https://mobstep.com/api/v2.0/coupon/auto_apply', {
        headers: { 'mobstep-customer-token': token }
    });
    return response.data;
};


// api.js

export const saveContent = async (type,body) => {
    const token = getToken(); // Ensure you have a function to get the token

    try {
        const response = await fetch(`https://mobstep.com/api/v2.0/${type}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mobstep-customer-token': token, // Include the token in the headers
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error saving address:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};



// Example of an API call that requires authorization
const fetchProtectedData = async () => {
    const token = getToken();
    const response = await axios.get('https://mobstep.com/protected/endpoint', {
        headers: {
            'mobstep-customer-token': token
        }
    });
    return response.data;
};

export const loginUser = async (email, password, appId) => {
    const response = await axios.post('https://mobstep.com/api/v2.0/customer/token', {
        mail: email,
        password: password,
        application_id: appId,
        identifier: email,
        method: "mail",
        platform: "web"
    });
    return response.data;
};


export const getKashierLink = async (order) => {
    const response = await axios.post('https://mobstep.com/payment/kashier/getlink', {
        id: order.id,
        total: order.total,
        customer_id: order.customer_id,
        application_id: order.application_id
    });
    return response.data;
};


export const registerUser = async (name, email, password, appId, identifier, method, platform) => {
    const payload = {
        name: name,
        mail: email,
        application_id: appId,
        identifier: identifier,
        method: method,
        platform: platform
    };

    // Only add the password if it is provided
    if (password) {
        payload.password = password;
    }

    const response = await axios.post('https://mobstep.com/api/v2.0/customer/register', payload);

    return response.data;
};

