// src/components/ProductCatalogBlockWhatsapp.js
import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppId} from "../AppIdContext";

const ProductCatalogBlockWhatsapp = ({whatsappNumber}) => {
    const appId = useAppId();
    const whatsappLink = `https://wa.me/${whatsappNumber.replace(/\s/g, '')}`;
    const {t} = useTranslation();
    const whatsappIcon = `/projects/${appId}/images/product_catalog_block_whatsapp_icon.png`;


    const handleWhatsappClick = () => {
        window.open(whatsappLink, '_blank');
    };

    return (
        <div className="product_catalog_block_whatsapp" onClick={handleWhatsappClick}>
            <img className="product_catalog_block_whatsapp_icon" src={whatsappIcon} alt="WhatsApp" />
            <div className="flex_1">
                <div className="product_catalog_block_whatsapp_title bold">{t("product_catalog_block_whatsapp_title")}</div>
                <div className="product_catalog_block_whatsapp_number bold">{whatsappNumber}</div>
            </div>
        </div>
    );
};

export default ProductCatalogBlockWhatsapp;
