import React, { useState } from 'react';

const ItemDetailsBlockAvailability = ({ item, onAavailabiltyChange, availabilityRequired }) => {
    const { availability_slots, availability_widget } = item;

    availabilityRequired(item.field_item_availability_required === "1");

    const renderAvailabilityWidget = () => {
        switch (availability_widget) {
            case 'slots':
                return (
                    <SlotsWidget
                        availabilitySlots={availability_slots}
                        multiple={item.field_item_availability_multiple === "1"}
                        basePrice={item.field_item_availability_base === "1"}
                        required={item.field_item_availability_required === "1"}
                        onAavailabiltyChange={onAavailabiltyChange}
                    />
                );
            case 'calendar':
                return <CalendarWidget availabilitySlots={availability_slots} />;
            case 'dayrange':
                return <DayRangeWidget availabilitySlots={availability_slots} />;
            default:
                return null;
        }
    };

    return (
        <div className="item_details_block_availability_holder">
            {renderAvailabilityWidget()}
        </div>
    );
};

const SlotsWidget = ({ availabilitySlots, multiple, basePrice, required, onAavailabiltyChange }) => {
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [currentBasePrice, setCurrentBasePrice] = useState(basePrice || 0);

    const formatAvailability = (day) => {
        return new Date(day).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const formatted = (day, slot) => {
        const startTimestamp = new Date(`${day}T${slot.start}`).toISOString().slice(0, 19);
        const endTimestamp = slot.end ? new Date(`${day}T${slot.end}`).toISOString().slice(0, 19) : null;
        return {
            value: startTimestamp,
            end_value: endTimestamp,
            price: slot.price,
        };
    };

    const handleSlotClick = (day, slot) => {
        const formattedSlot = formatted(day, slot);

        let updatedSlots;
        if (multiple) {
            if (selectedSlots.some(s => s.value === formattedSlot.value && s.end_value === formattedSlot.end_value)) {
                updatedSlots = selectedSlots.filter(s => !(s.value === formattedSlot.value && s.end_value === formattedSlot.end_value));
            } else {
                updatedSlots = [...selectedSlots, formattedSlot];
            }
        } else {
            updatedSlots = [formattedSlot];
        }

        setSelectedSlots(updatedSlots);
        setCurrentBasePrice(slot.price);
        onAavailabiltyChange(updatedSlots, required, basePrice , slot.price);
    };

    return (
        <div className="item_details_block_availability flex">
            {availabilitySlots.map((availability) => (
                <div key={availability.day} className="item_details_block_availability_group">
                    <div className="item_details_block_availability_group_title bold">{formatAvailability(availability.day)}</div>
                    <div className="item_details_block_availability_group_list">
                        {availability.time_slots.map((slot) => {
                            const isSelected = selectedSlots.some(s =>
                                s.value === new Date(`${availability.day}T${slot.start}`).toISOString().slice(0, 19) &&
                                s.end_value === (slot.end ? new Date(`${availability.day}T${slot.end}`).toISOString().slice(0, 19) : null)
                            );
                            return (
                                <div
                                    key={slot.start}
                                    className={`item_details_block_availability_group_item pointer flex ${isSelected ? 'item_details_block_availability_group_item_selected' : ''}`}
                                    onClick={() => handleSlotClick(availability.day, slot)}
                                >
                                    <div className="item_details_block_availability_group_item_date">{slot.start} {slot.end ? `- ${slot.end}` : ''}</div>
                                    <div className="item_details_block_availability_group_item_price">${slot.price}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

const CalendarWidget = ({ availabilitySlots }) => (
    <div>
        {/* Placeholder for the actual calendar widget component */}
        Calendar Widget
    </div>
);

const DayRangeWidget = ({ availabilitySlots }) => (
    <div>
        {/* Placeholder for the actual calendar widget component */}
        DayRange Widget
    </div>
);

export default ItemDetailsBlockAvailability;
