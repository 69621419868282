import React from 'react';
import {useTranslation} from "react-i18next";

const OrdersBlockSummary = ({ order }) => {
    const {t} = useTranslation();
    return (
        <div className="orders_block_summary mode-invoice">
            <div className="orders_block_summary_subtotal flex">
                <div className="flex_1 orders_block_summary_subtotal_text">{t("orders_block_summary_subtotal_text")}</div>
                <div className="">{order.subtotal}</div>
            </div>
            <div className="orders_block_summary_coupon flex">
                <div className="flex_1 orders_block_summary_coupon_text">{t("orders_block_summary_coupon_text")}</div>
                <div className="">{order.coupon}</div>
            </div>
            <div className="orders_block_summary_discount flex">
                <div className="flex_1 orders_block_summary_discount_text">{t("orders_block_summary_discount_text")}</div>
                <div className="">{order.discount}</div>
            </div>
            <div className="orders_block_summary_tax flex">
                <div className="flex_1 orders_block_summary_tax_text">{t("orders_block_summary_tax_text")}</div>
                <div className="">{order.tax}</div>
            </div>
            <div className="orders_block_summary_service_fee flex">
                <div className="flex_1 orders_block_summary_service_fee_text">{t("orders_block_summary_service_fee_text")}</div>
                <div className="">{order.service_fee}</div>
            </div>
            <div className="orders_block_summary_wallet flex">
                <div className="flex_1 orders_block_summary_wallet_text">{t("orders_block_summary_wallet_text")}</div>
                <div className="">- {order.wallet_amount}</div>
            </div>
            <div className="orders_block_summary_total flex">
                <div className="flex_1 orders_block_summary_total_text bold">{t("orders_block_summary_total_text")}</div>
                <div className="bold">{order.total}</div>
            </div>
        </div>
    );
};

export default OrdersBlockSummary;
