import React, { useEffect, useState } from 'react';
import { fetchWalletCredit } from '../services/api';
import { isLoggedIn } from '../services/auth';
import { useTranslation } from "react-i18next";
import { useAppId } from "../AppIdContext";
import { useNavigate } from 'react-router-dom';

const ProductCatalogBlockWalletCredit = () => {
    const [customerCredit, setCustomerCredit] = useState(0);
    const [actualUserCredits, setActualUserCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const appId = useAppId();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn()) {
            fetchWalletCredit(appId)
                .then(credit => {
                    if (credit.length > 0) {
                        const userCredits = credit[0].balance_after;
                        setCustomerCredit(userCredits);
                        //const actualCredits = cart.getActualUserCredits(userCredits);
                        // setActualUserCredits(actualCredits);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
        }
    }, [isLoggedIn(), appId]);

    if (!isLoggedIn() || loading || error) {
        return null;
    }
    

    return (
            <div className="product_catalog_block_wallet flex">
                <div className="product_catalog_block_wallet_title product_catalog_block_wallet_title_text bold">{t("product_catalog_block_wallet_title_text")}: </div>
                <div className="product_catalog_block_wallet_credit flex_1 bold"> ( {customerCredit} )</div>
            </div>
    );
};

export default ProductCatalogBlockWalletCredit;
